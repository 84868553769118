import React from "react";
import { Button } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";

function SupportButton() {
  const handleSupportClick = () => {
    window.location.href = "mailto:yifei.li@sap.com?subject=Support%20Request";
  };

  return (
    <Button
      variation="link"
      className="support-button"
      onClick={handleSupportClick}
    >
      {I18n.get("Support")}
    </Button>
  );
}

export default SupportButton;
