// See https://docs.amplify.aws/lib/utilities/i18n/q/platform/js/#putvocabularies
export const languageDict = {
  de: {
    "Enter your Username": "Geben Sie Ihren Benutzernamen ein",
    "Sign Out": "Abmelden",
    "Support": "Unterstützung",
    "Please select a file!": "Bitte wählen Sie eine Datei aus!",
    "No files selected.": "Keine Dateien ausgewählt.",
    "File not found or empty": "Datei nicht gefunden oder leer",
    "DownloadZip error:": "DownloadZip-Fehler:",
    "Invalid objectID": "Ungültige Objekt-ID",
    "Download error:": "Download Fehler:",
    "DownloadBlob error:": "DownloadBlob-Fehler",
    "Failed to fetch the PDF. Response status:":
      "Das PDF konnte nicht abgerufen werden. Antwortstatus:",
    "Error while trying to print the PDF:":
      "Fehler beim Versuch, das PDF zu drucken:",
    "Error while trying to print merged PDFs:": 
    "Fehler beim Versuch, zusammengeführte PDFs zu drucken:",
    "You cannot select more than ": "Sie können nicht mehr als ",
    " files!": " Dateien auswählen!",
    "Find documents with...": "Dokumente suchen mit ...",
    "Search here...": "Suche hier...",
    "Narrow the search...": "Die Suche einschränken ...",
    "Result type": "Ergebnistyp",
    "All Documents": "Alle Ergebnistypen",
    "All of these words:": "Alle folgenden Wörter:",
    "Which search method...": "Wie soll gesucht werden ...",
    "Search mode": "Suchart",
    "And": "Und",
    'Or': "Oder",
    "Add property restrictions...":
      "Suchkriterien in Eigenschaft hinzufügen ...",
    "Search": "Suchen",
    "Loading...": "Wird geladen...",
    "Downloading...": "Wird heruntergeladen...",
    "Invalid filter input": "Ungültige Filtereingabe",
    "Where the property...": "Wenn die Eigenschaft...",
    "(Pick Property)": "(Eigenshaft auswählen)",
    "Invoice": "Rechnung",
    "Debit Note (misc.)": "Belastung (Sonstiges)",
    "Credit Note (misc.)": "Gutschrift (Sonstiges)",
    "Credit Note (Goods return)": "Gutschrift (Rückware)",
    "Documents (Bonus)": "Belege (Bonus)",
    "Documents (Techn.)": "Belege (Techn.)",
    "Self-billing Sales": "Self-billing Sales",
    "Self-billing Manuf": "Self-billing Manuf",
    "Currency": "Währung",
    "Doc. Issuer": "Belegaussteller",
    "Document state": "Dokumentenstatus",
    "Partner Nr at Issuer": "PartnerNr. beim Sender",
    "Partner Nr at Receiver": "PartnerNr. beim Empfänger",
    "Total invoice amount": "Fakturaendbetrag",
    "VAT ID (Doc. Receiver)": "UmsatzSteuer-ID (Belegempfänger)",
    "Equals": "Ist gleich",
    "Earlier than": "Früher als",
    "Later than": "Später als",
    "Does not equal": "Ist nicht gleich",
    "Greater than": "Größer als",
    "Less than": "Kleiner als",
    "Contains": "Enthält",
    "Does not contain": "Enthält nicht",
    "Begins with": "Beginnt mit",
    "Type": "Typ", 
    "Document No.": "Belegnummer",
    "Document Date": "Belegdatum",
    "Document type": "Inhaltstyp",
    "Select file type": "Dateityp auswählen",
    "Signature": "Signatur anzeigen",
    "Verification report": "Verifikationsbericht anzeigen",
    "Additional Files": "Zugehörige Dokumente",
    "Print": "Drucken",
    "New": "Neu",
    "Read": "Gelesen",
    "Downloaded": "Heruntergeladen",
    "Help": "Hilfe"
  },
};
